import React, { useState, useEffect } from 'react';

export default function GradientSquare({
    degree,
    colorFrom,
    colorTo,
    duration,
    imageUrl
 }) {
    const [gradientColor, setGradientColor] = useState(colorFrom);

    useEffect(() => {
        const startTime = Date.now();

        const updateGradientColor = () => {
            const currentTime = (new Date()).getTime(); // Date.now();
            const elapsedTime = currentTime - startTime;

            // Calculate the gradient color based on elapsed time and duration
            const progress = Math.min(1, elapsedTime / duration);
            const rateFrom = progress < 0.5 ? progress * 200 : 100;
            const rateTo = progress < 0.5 ? 0 : (progress-0.5) * 200;

            const gradientColorValue = progress >= 1 
                ? colorTo 
                : `linear-gradient(${degree}deg, ${colorTo} ${rateTo}%, ${colorFrom} ${rateFrom}%)`;

            setGradientColor(gradientColorValue);

            // Check if the animation is complete
            if (progress < 1) {
                requestAnimationFrame(updateGradientColor);
            }
        };

        updateGradientColor();
    }, [duration, degree, colorFrom, colorTo]);

    const size='400px';
    return (
        <div>
            <div
                style={{
                    width: size,
                    height: size,
                    background: gradientColor,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
            <img src={imageUrl} alt="Lab is Fun, Inc." style={{ width: '80%', height: '80%', boxSizing:'border-box' }} />
            </div>
        </div>
    );
};
