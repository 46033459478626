// AppMain.js
//import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import LabIsFunLanding from './LabIsFunLanding';

const AppMainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%', width: '100%',
});

const Footer = styled(Box)({
    position: 'absolute',
    boxSizing: 'border-box',
    width: '100%',
    bottom: 0,
    padding: 5,
    fontSize: '0.9rem',
    backgroundColor: '#eee',
    textAlign: 'center',
    fontStyle: 'italic',
    color: 'rgb(63, 90, 65)'
});


export default function AppMain() {


    const onClickMail = () => {
        alert('Welcome to Lab is Fun, Inc., but we do not expose our email address.');
    };

    return (
        <AppMainBox>
            <LabIsFunLanding />
            <Footer>
                    Lab is Fun, Inc. &nbsp; &nbsp;
                Seoul, Korea, Planet Earth, Virgo Supercluster of galaxies.
                &nbsp; &nbsp;
                <a onClick={onClickMail} style={{color:'rgb(63, 90, 65)'}}>email-n-tel@wont.be.exposed</a>
            </Footer>
        </AppMainBox>
    );
}
