import { useEffect, useState } from 'react';
import AppMain from './com/AppMain';

function App() {
  return(<AppMain />);

  /* return (
    <div className="App">
      <div className="LIF">
        <p>&nbsp;</p>
        {
          Boolean(colorFrom) && Boolean(colorTo) && SupportReqAnimFrame
          ?
          <GradientSquare
            imageUrl={LabisFunLogo}
            colorFrom={colorFrom}
            colorTo={colorTo}
            degree={deg}
            duration={3000}
          />
          :
          <div
            style={{
              width: size,
              height: size,
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
          <img src={LabisFunLogo} alt="Lab is Fun, Inc." style={{ width: '80%', height: '80%', boxSizing:'border-box' }} />
          </div>
        }
        <img src={LabisFunText} alt="Lab is Fun, Inc." width={540} style={{marginTop:40}}/>
        <div style={{marginTop:20, fontSize:"1.4rem", color:LogoColor}}>
        <i>Announcing <a href="https://www.versionzeromaker.com/" style={{color:'#045BA2'}} >Version Zero Maker...</a></i>
        </div>
      </div>
      <div className='Foot'>
        Lab is Fun, Inc. &nbsp; &nbsp;
      Seoul, Korea, Planet Earth, Virgo Supercluster of galaxies.
      &nbsp; &nbsp;
      <a href="#" style={{color:'rgb(63, 90, 65)'}}>email-n-tel@wont.be.exposed</a>
      </div>
    </div>
  ); */
}

export default App;
